<template>
  <div class="introduction">
    <div v-html="terms" class="intro-text"></div>
    <div v-if="!terms" style="background:#fff;" class="container690">
      <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" class="emptyImg" />
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    data() {
      return {};
    },
    filters: {},
    created() {
      // console.log("terms", this.terms);
    },

    computed: {
      ...mapState({
        terms: (state) => {
          if (state.Process.headerData) {
            return state.Process.headerData.terms;
          }
        },
      }),
    },

    methods: {},
  };
</script>
<style lang="scss" scoped>
  .introduction {
    ::v-deep ul {
      list-style: disc !important;
    }

    ::v-deep ul,
    ::v-deep ol {
      padding-left: 40px !important;
    }

    ::v-deep ul li {
      list-style: disc;
    }

    ::v-deep ol li {
      list-style: decimal;
    }
  }
  @media (min-width: 576px) {
    .intro-text {
      color: #666;
      padding: 20px 0;
      word-break: keep-all !important;
    }
    .emptyImg{
      margin: 100px auto; 
      display: block
    }
  }
  @media (max-width: 576px) {
    .intro-text {
      color: #666;
      padding: 10px 0;
      word-break: keep-all !important;
    }
    .emptyImg{
      margin: 20px auto; 
      display: block
    }
  }
</style>